import React from "react";
import "./MedSynopsisFeedback.css";

const AISummary: React.FC<{ summary?: string }> = ({ summary }) => {
  if (!summary) {
    return <div>No summary available</div>;
  }

  return (
    <div>
      <p className="ai-summary-text">{summary}</p>
    </div>
  );
};

export default AISummary;

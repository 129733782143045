// src/store/store.ts
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "./userSlice";
import quizSlice from "./medSynopsis/quizSlice";
import feedbackSlice from "./medSynopsis/feedbackSlice";

// Combine all the reducers
const rootReducer = combineReducers({
  user: userReducer,
  medSynopsisQuiz: quizSlice,
  medSynopsisFeedback: feedbackSlice,
  // add other reducers her
});

// Persist configuration for only specific reducers
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "medSynopsisQuiz", "medSynopsisFeedback"], // only 'user' will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;

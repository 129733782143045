import React from "react";
import "./SubTriviaCategory.css";
import PrivateRoute from "../../Utils/PrivateRoute/PrivateRoute";
import { BackArrowSvg } from "../../components/Svgs/svgsComponents";
import { useNavigate, useParams } from "react-router-dom";
import { SubTriviaCategoryData } from "./SubTriviaCategoryData";
import { useQuery } from "@apollo/client";
import { GET_SUB_CATEGORY } from "../../graphql/queries/getDashboardSubCategory";
import { GetSubcategoryI } from "../../types/GetSubCategory";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";

const SubTriviaCategory = () => {
  const navigate = useNavigate();

  const { triviaType, quizUUID } = useParams();

  const { error, loading, data } = useQuery<GetSubcategoryI>(GET_SUB_CATEGORY, {
    variables: {
      category: triviaType === "basic-sciences" ? "Basic Sciences" : null,
    },
  });

  // Spinner Loads
  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <PrivateRoute>
      <div className="medical-trivia">
        <div className="container medical-trivia__main">
          <div className="medical-trivia__header">
            <div
              onClick={() => navigate("/dashboard")}
              className="medical-trivia-back-nav "
            >
              {BackArrowSvg()}
            </div>
            <p>
              {triviaType === "basic-sciences"
                ? "Basic Sciences trivia quiz"
                : "Medical timed trivia quiz"}
            </p>
          </div>

          <div className="medical-trivia__cards">
            {data &&
              [...data?.getSubategory, ...SubTriviaCategoryData].map(
                ({ coverImage, subcategory, totalNumber }, index) => (
                  <div
                    className="medical-trivia__card"
                    key={index}
                    onClick={() => {
                      if (coverImage !== "") {
                        navigate(
                          `/trivia/${triviaType}/${quizUUID}/${subcategory?.id}`
                        );
                      }
                    }}
                  >
                    {coverImage !== "" && (
                      <div className="medical-trivia__card-img">
                        <img src={coverImage} alt={subcategory?.subcat} />
                      </div>
                    )}

                    <p
                      className={`${
                        coverImage !== ""
                          ? "medical-trivia__card-content"
                          : "medical-trivia__card-content-rotate"
                      }`}
                    >
                      {subcategory?.subcat}
                    </p>
                  </div>
                )
              )}
          </div>
        </div>

        {error && <p>{error.message}</p>}
      </div>
    </PrivateRoute>
  );
};

export default SubTriviaCategory;

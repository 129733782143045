import React, { useState } from "react";
import "./Login.css";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../graphql/mutatons/authentication";
import client from "../../graphql/client";
import { toast } from "react-toastify";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { useDispatch } from "react-redux";
import { setUser } from "../../state/userSlice";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const initFormDara = {
    email: "",
    password: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState(initFormDara);

  const { authenticateUser } = useAuth();

  const location = useLocation();

  const redirectPath = location.search.replace("?", "").trim() || "";

  const togglePasswordEye = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Mutation
  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    client,
  });

  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    await loginUser({
      variables: {
        loginInput: formData,
      },
    })
      .then((res) => {
        // Set token to localStorage
        localStorage.setItem("token", res?.data?.loginUser?.access_token);

        // console.log(res?.data, " User data -----");

        dispatch(setUser(res?.data?.loginUser?.user));

        //  Auth user to true
        authenticateUser();

        // If user is redirected to login when trying to access a paticular protected route
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate("/");
        }

        toast.success("User login successfully");
      })
      .catch((error) => {
        toast.error(error!.message);
      });
  };

  const handleSignUpNav = () => {
    // If user is redirected to login when trying to access a paticular protected route
    if (redirectPath) {
      navigate(`/signup?${redirectPath}`);
    } else {
      navigate("/signup");
    }
  };

  // Spinner
  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div className="login">
      <AuthHeader title="Join Us!" isLogin={true} />

      <form className="auth-form" onSubmit={handleLoginSubmit}>
        <div className="auth-input-con">
          <p>Email Address</p>

          <input
            type="text"
            placeholder="Insert your email"
            className="input-text"
            value={formData.email}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                email: e.target.value,
              }));
            }}
            required
          />
        </div>

        <div className="auth-input-con">
          <p>Password</p>

          <div className="password">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="passwword"
              className="input-password"
              value={formData.password}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
              }}
              required
            />
            {showPassword ? (
              <IoEyeOutline onClick={() => togglePasswordEye()} />
            ) : (
              <IoEyeOffOutline onClick={() => togglePasswordEye()} />
            )}
          </div>
        </div>

        <div className="agree-con">
          <div className="check-con">
            <div
              className="custom-checkBox"
              onClick={() => setIsChecked((prevState) => !prevState)}
            >
              {isChecked && <FaCheck />}
            </div>

            <p>Keep me logged in</p>
          </div>

          <p
            onClick={() => navigate("/forget-password")}
            className="forget-password"
          >
            Forgot password?
          </p>
        </div>

        <button className="auth-btn" type="submit">
          Login
        </button>

        <div className="auth-suggestion">
          <p>Don't have an account?</p>

          <p
            className="suggest"
            onClick={() => {
              handleSignUpNav();
            }}
          >
            Register
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackArrowSvg } from "../../Svgs/svgsComponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state";
import { IoMdClose, IoMdTime } from "react-icons/io";
import TextEditor from "../../Common/Inputs/TextEditor";
import { GENERATE_SUMMARY_SCORE } from "../../../graphql/queries/medSynopsis";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { feedbackAction } from "../../../state/medSynopsis/feedbackSlice";
import Modal from "../../Modal/Modal";

const QuizQuestions: React.FC<{ quizData: any }> = ({ quizData }) => {
  const [countDown, setCountDown] = useState(0);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [subscribeMessage, setSubscibeMessage] = useState("");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const quizInfo = useSelector((state: RootState) => state.medSynopsisQuiz);
  const [generateMedSummary, { loading: generating }] = useMutation(
    GENERATE_SUMMARY_SCORE
  );
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (quizData?.caseContent) {
      const caseContentElements = document.querySelectorAll(".case-content *");
      caseContentElements.forEach((element) => {
        if (element instanceof HTMLElement) {
          element.style.color = "#fff";
          element.style.display = "flex";
          element.style.flexDirection = "column";
          element.style.gap = "15px";
        }
      });
    }
  }, [quizData?.caseContent]);

  useEffect(() => {
    if (quizInfo.type === "untimed") {
      return;
    }
    intervalRef.current = setInterval(() => {
      setCountDown((prev) => prev + 1);
    }, 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    }
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSend = async (text: string) => {
    // Strip out all HTML tags and decode HTML entities
    const plainText = text.replace(/<[^>]*>/g, "").trim();

    if (plainText === "") {
      toast.error("Please enter a valid summary");
      return;
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    try {
      let response = await generateMedSummary({
        variables: {
          medSummaryScoreInput: {
            caseUUID: quizData?.caseUUID,
            categoryName: quizData?.categoryTitle || quizData?.categoryName,
            userSummary: text,
            gameType: quizInfo.type,
            completionTime: formatTime(countDown),
          },
        },
      });

      dispatch(
        feedbackAction.setFeedback({
          feedback: response.data.generateMedCaseSummaryScore,
        })
      );
      navigate(`/dashboard/med-synopsis/${quizData.caseUUID}/feedback`);
    } catch (err: any) {
      if (err.message === "INSUFFICIENT_CREDIT") {
        setSubscibeMessage(
          "Your current credit balance for AI services has been exhausted. To continue using our AI features, please upgrade your account to receive additional credits."
        );
        setShowSubscribeModal(true);
        return;
      }
      if (err.message === "INTRO_INSUFFICIENT_CREDIT") {
        setSubscibeMessage(
          "Experience our AI features at no cost by signing up for a free trial, which includes complimentary AI credits"
        );
        setShowSubscribeModal(true);
        return;
      } else {
        toast.error(`${err.message || "Something went wrong"}`);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div
            onClick={() => navigate(-1)}
            className="medical-trivia-back-nav "
          >
            {BackArrowSvg()}
          </div>
          <p>{quizInfo.quizTitle}</p>
        </div>
        {quizInfo.type === "timed" && (
          <div
            style={{
              backgroundColor: "#E3AF61",
              display: "flex",
              alignItems: "center",
              gap: 10,
              padding: "5px 10px",
              color: "black",
              borderRadius: 4,
            }}
          >
            <IoMdTime />
            <p>{formatTime(countDown)}</p>
          </div>
        )}
      </div>
      <div className="look-at-case">Look at this case</div>

      <div
        className="case-content"
        dangerouslySetInnerHTML={{ __html: quizData.caseContent }}
      />

      <div className="text-area-container" style={{ marginBottom: 50 }}>
        <div
          role="button"
          onClick={() => setExpanded(!expanded)}
          style={{
            position: "absolute",
            right: 20,
            top: -10,
            cursor: "pointer",
          }}
        >
          <img
            src="/images/medSynopsis/expand-icon.png"
            style={{ width: 20, height: 20 }}
            alt=""
          />
        </div>
        <p>
          <span
            className="text-title"
            style={{ display: "block", marginBottom: 10 }}
          >
            Question:
          </span>
          <span className="text-content">
            Write a concise discharge summary to the patient’s family doctor.
          </span>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            minHeight: expanded ? "450px" : "350px",
            marginBottom: 10,
          }}
        >
          <TextEditor
            onSubmit={handleSend}
            expanded={expanded}
            loading={generating}
          />
        </div>
      </div>

      {/* subscribe modal */}
      {showSubscribeModal && (
        <Modal extraFunc={() => setShowSubscribeModal(false)}>
          <div className="ai-modal">
            <div
              role="button"
              onClick={() => setShowSubscribeModal(false)}
              style={{
                position: "absolute",
                top: 10,
                right: 20,
                cursor: "pointer",
              }}
            >
              <IoMdClose size={20} />
            </div>
            <div>
              <h5>Insufficient credits</h5>
              <p>{subscribeMessage}</p>
              <div
                className="subscribe-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSubscribeModal(false);
                  navigate("/dashboard/settings");
                }}
              >
                Subscribe Now
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default QuizQuestions;

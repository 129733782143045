import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";

const TextEditor = ({
  onSubmit,
  expanded,
  loading,
}: {
  onSubmit: (text: string) => void;
  expanded: boolean;
  loading: boolean;
}) => {
  const [value, setValue] = useState("");

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <div
        style={{
          height: expanded ? "420px" : "320px",
        }}
      >
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={{
            toolbar: [
              ["bold", "italic", "underline"],
              [{ header: [1, 2, 3, false] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
            ],
          }}
          style={{ height: expanded ? "350px" : "250px", width: "100%" }}
        />
      </div>
      <div className="submit-btn" onClick={() => onSubmit(value)}>
        {loading ? "Loading..." : " Submit"}
      </div>
    </div>
  );
};

export default TextEditor;

import React, { useEffect, useRef, useState } from "react";

import "./DashboardProfile.css";
import { cameraIconSvg, dropdownIconSvg } from "../Svgs/svgsComponents";
import { useSelector } from "react-redux";
import { RootState } from "../../state";
import { UserI } from "../../types/AuthResponse";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_SPECIALTY } from "../../graphql/queries/getSpecialties";
import { UserSpecialty, UserUpdateDataI } from "../../types/userProfilTpes";

import { countryList, countryStateCites } from "../../Utils/country-state";
import BackDrop from "../BackDrop/BackDrop";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { UPDATE_USER_PROFILE } from "../../graphql/mutatons/updateUserProfile";
import client from "../../graphql/client";
import { toast } from "react-toastify";
import { GET_USER_PROFILE } from "../../graphql/queries/getUser";
import { useDispatch } from "react-redux";
import { setUser, updateUser } from "../../state/userSlice";
import { UPLOAD_PROFILE_IMAGE } from "../../graphql/mutatons/uploadFiles";
import { DEACTIVATE_ACCOUNT } from "../../graphql/queries/getDeactivateAccount";
import { useAuth } from "../../context/AuthContext";

export const DashboardProfile = () => {
  const dispatch = useDispatch();

  const { logout } = useAuth();

  const user = useSelector((state: RootState) => state.user) as UserI;
  const formBtnRef = useRef<HTMLButtonElement | null>(null);
  const imageSetRef = useRef<HTMLInputElement | null>(null);

  const [formData, setFormData] = useState<UserUpdateDataI>({
    firtName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    specialty: user?.specialty || "Specialty",
    profileImg: user?.profileImage || "",
    userName: user?.username || "",
    country: user?.country.country || "",
    stateCity: user?.state_city || "",
    subspecialty: user?.subspecialty || "",
  });

  const [specialies, setSpecialties] = useState<UserSpecialty[] | []>([]);

  const [showSpecialty, setShowSpecialty] = useState(false);
  const [showCountry, setShowCountry] = useState(false);
  const [showState, setShowState] = useState(false);
  const [isRefecth, setIsRefetch] = useState(false);

  // Query
  const { data, loading, error } = useQuery(GET_SPECIALTY);

  // Fetch user data update as we enter Dashboard
  const {
    loading: userLoading,
    error: userError,
    data: userData,
    refetch: userRefetch,
  } = useQuery(GET_USER_PROFILE);

  // Lazy get
  const [deactiveAccount, { loading: loadingDeactiveAcount }] =
    useLazyQuery(DEACTIVATE_ACCOUNT);

  // Mutation

  const [updateUserProfile, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER_PROFILE,
    {
      client,
    }
  );

  const [uploadProfileImage, { loading: uploadProfileImageeLoading }] =
    useMutation(UPLOAD_PROFILE_IMAGE, { client });

  const handleSetImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return toast.warn("No file selected");

    // Access the selected files
    const fileData = event.target.files?.[0]; // Get the first file

    if (
      fileData &&
      (fileData.type === "image/png" || fileData.type === "image/jpeg")
    ) {
      await uploadProfileImage({
        variables: {
          file: fileData,
        },
      })
        .then((res) => {
          const profileImg = res?.data?.uploadProfileImage?.secure_url;
          setFormData((prev) => ({
            ...prev,
            profileImg: profileImg,
          }));

          dispatch(updateUser({ profileImage: profileImg }));

          toast.success("Image uploaded successfully");
        })
        .catch((err) => {
          toast.error(err?.message || "Unexpected error occured ");
        });
    } else {
      toast.error("Please select a PNG or JPEG image.");
    }
  };

  const handleUpdateUserProfile = async () => {
    const payload = {
      country: formData?.country,
      firstName: formData?.firtName,
      lastName: formData?.lastName,
      specialty: formData?.specialty,
      state_city: formData?.stateCity,
      username: user?.username,
      subspecialty: formData?.subspecialty,
    };

    await updateUserProfile({
      variables: {
        editProfileInput: {
          ...payload,
        },
      },
    })
      .then((res) => {
        toast.success(res?.data?.updateProfile?.message);

        dispatch(updateUser(payload));
      })
      .catch((err) => {
        toast.error(err?.message || "Unexpected error ocured");
      });
  };

  // deleete account
  const handleDeletAccount = async () => {
    // delete account

    await deactiveAccount()
      .then((res) => {
        console.log(res, " reseponse ");
        toast.success(res?.data?.deactivateAccount?.message);
        logout();
      })
      .catch((err) => {
        toast.error(err?.message || "Unexpected error occured");
      });
  };

  useEffect(() => {
    if (data) {
      setSpecialties([...data?.getAllSpecialties]);
    }

    if (error) {
      toast.error(error?.message || "An unexpected error occured");
    }

    if (userData) {
      dispatch(setUser(userData.getProfile));

      setFormData((prev) => ({
        ...prev,
        firtName: userData.getProfile?.firstName || "",
        lastName: userData.getProfile?.lastName || "",
        email: userData.getProfile?.email || "",
        specialty: userData.getProfile?.specialty || "Specialty",
        profileImg: userData.getProfile?.profileImage || "",
        userName: userData.getProfile?.username || "",
        country: userData.getProfile?.country.country || "",
        stateCity: userData.getProfile?.state_city || "",
        subspecialty: userData?.getProfile?.subspecialty || "",
      }));
      setIsRefetch(true);
    }

    if (userError) {
      // console.log(userError, "user error");
      toast.error(userError?.message || "Unexpected errorr occured");
    }

    if (!isRefecth) {
      userRefetch();
      setIsRefetch(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, userData, userError]);

  if (
    loading ||
    updateUserLoading ||
    userLoading ||
    uploadProfileImageeLoading ||
    loadingDeactiveAcount
  ) {
    return <SpinnerLoader loading={true} />;
  }
  return (
    <div className="dashboard-profile-con">
      <div className="dashboard-profile-header">
        <p>Personal Information</p>

        <button
          className="dashboard-profile-header__btn"
          type="submit"
          onClick={() => {
            if (formBtnRef.current) {
              formBtnRef!.current!.click();
            }
          }}
        >
          Save
        </button>
      </div>

      <div className="dashboard-profile__main">
        <div className="dashboard-profile__main-profile-id">
          <p>@ {formData?.userName}</p>

          <div>
            <img
              src={formData?.profileImg || "/images/guest.svg"}
              alt="user prifile pictire"
            />

            <span
              onClick={() => {
                if (imageSetRef.current) {
                  imageSetRef.current.click();
                }
              }}
            >
              {cameraIconSvg()}
            </span>

            <input
              type="file"
              accept="image/png, image/jpeg"
              className="form-hide"
              ref={imageSetRef}
              onChange={(event) => handleSetImage(event)}
            />
          </div>
        </div>

        <div className="dashboard-profile__main-form-con">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateUserProfile();
            }}
          >
            <div className="auth-input-con">
              <p>
                <span className="red">* </span> First Name
              </p>

              <input
                type="text"
                placeholder="First name"
                className="input-text "
                value={formData?.firtName}
                onChange={(event) =>
                  setFormData((prev) => ({
                    ...prev,
                    firstName: event.target.value,
                  }))
                }
                required
              />
            </div>

            <div className="auth-input-con">
              <p>
                <span className="red">* </span> Last Name
              </p>

              <input
                type="text"
                placeholder="Last name"
                className="input-text "
                value={formData?.lastName}
                onChange={(event) =>
                  setFormData((prev) => ({
                    ...prev,
                    lastName: event.target.value,
                  }))
                }
                required
              />
            </div>

            <div className="auth-input-con">
              <p>
                <span className="red">* </span> Email
              </p>

              <input
                type="text"
                placeholder="Email"
                className="input-text "
                value={formData?.email}
                readOnly
              />
            </div>

            <div className="speccialty-con">
              <div
                className="select-input-custom"
                onClick={() => setShowSpecialty((prev) => !prev)}
              >
                <div>{formData?.specialty}</div>

                <div>{dropdownIconSvg()}</div>
              </div>

              {showSpecialty && (
                <div className="specialty-drop">
                  {specialies.map(({ specialty }, index) => (
                    <p
                      key={index}
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          specialty: specialty,
                        }));
                        setShowSpecialty(false);
                      }}
                    >
                      {specialty}
                    </p>
                  ))}
                </div>
              )}
            </div>

            {/* Only if specialty is others */}

            {formData?.specialty === "Other" && (
              <div className="auth-input-con">
                <input
                  type="text"
                  placeholder="other"
                  className="input-text"
                  value={formData?.subspecialty}
                  onChange={(event) => {
                    setFormData((prev) => ({
                      ...prev,
                      subspecialty: event.target.value,
                    }));
                  }}
                  required
                />
              </div>
            )}

            <div className="profile-country-state">
              <div className="profile-country-state__item">
                <div
                  className="auth-input-con"
                  onClick={() => setShowCountry((prev) => !prev)}
                >
                  <p>Country</p>

                  <input
                    type="text"
                    placeholder="Country"
                    className="input-text "
                    onChange={() => {}}
                    value={formData?.country}
                    required
                  />
                </div>
                {showCountry && (
                  <div className="profile-country-state__drop-down">
                    {countryList.map((countryValue, index) => (
                      <p
                        className=""
                        key={index}
                        onClick={() => {
                          setFormData((prev) => ({
                            ...prev,
                            country: countryValue,
                          }));

                          setFormData((prev) => ({
                            ...prev,
                            stateCity: "",
                          }));

                          setShowCountry(false);
                        }}
                      >
                        {countryValue}
                      </p>
                    ))}
                  </div>
                )}{" "}
              </div>

              <div className="profile-country-state__item">
                <div
                  className="auth-input-con"
                  onClick={() => {
                    if (formData?.country !== "") {
                      setShowState(true);
                    }
                  }}
                >
                  <p>State/City</p>

                  <input
                    type="text"
                    placeholder="State/city"
                    className="input-text "
                    value={formData?.stateCity}
                    onChange={(event) =>
                      setFormData((prev) => ({
                        ...prev,
                        stateCity: event.target.value,
                      }))
                    }
                    required
                  />
                </div>

                {showState && formData?.country && (
                  <div className="profile-country-state__drop-down">
                    {countryStateCites[formData.country].map(
                      (stateValue: string, index: number) => (
                        <p
                          className=""
                          key={index}
                          onClick={() => {
                            setFormData((prev) => ({
                              ...prev,
                              stateCity: stateValue,
                            }));
                            setShowState(false);
                          }}
                        >
                          {stateValue}
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>

            <button ref={formBtnRef} className="form-hide" type="submit">
              submit
            </button>
          </form>

          <p
            className="form-deleete-account red"
            onClick={() => handleDeletAccount()}
          >
            Delete account
          </p>
        </div>
      </div>

      <BackDrop
        isShow={
          showCountry ||
          showSpecialty ||
          (showState && formData?.country !== "")
        }
        extraFunc={() => {
          setShowCountry(false);
          setShowSpecialty(false);
          setShowState(false);
        }}
      />
    </div>
  );
};

import { gql } from "@apollo/client";

export const GET_ALL_PRODUCTS = gql`
  query GetAllProduct {
    getAllProduct {
      amount {
        monthly
        yearly
        payPerUse
      }
      casePresentation
      caseRecall
      credits
      generalTrivia
      medicalTrivia
      medsynopsis
      multiplayerCapacity
      plan
      productUUID
      quizAI
      storage
      stripeProduct {
        monthlyPriceId
        stripeProductId
        yearlyPriceId
      }
      tokenTopUp {
        amount
        unitAmount
        value
      }
    }
  }
`;

import React from "react";
import "./MarkingScheme.css";

const MarkingScheme = () => {
  return (
    <div className="marking-scheme-container">
      <ul
        style={{
          listStyle: "none",
          gap: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {markingScheme.map((item) => (
          <li key={item.id}>
            <p style={{ marginBottom: 5, fontWeight: "600" }}>{item.title}:</p>
            <ul
              style={{
                paddingLeft: 20,
                display: "flex",
                flexDirection: "column",
                gap: 5,
                listStyle: "disc",
              }}
            >
              {item.items.map((item, index) => (
                <li key={index}>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MarkingScheme;

const markingScheme = [
  {
    id: 1,
    title: "Accuracy",
    items: [
      "Check if the clinical details (chief complaint, history, examination findings, investigations, diagnosis, and treatment) are correct and complete.",
      "Deduct points for any inaccuracies or omissions.",
    ],
  },
  {
    id: 2,
    title: "Clarity and Conciseness",
    items: [
      "Evaluate if the summary is easy to read, avoiding unnecessary details or medical jargon.",
      "Deduct points for lack of clarity, overly verbose sections, or excessively brief descriptions.",
    ],
  },
  {
    id: 3,
    title: "Relevance",
    items: [
      "Ensure all included information is pertinent to the case and unnecessary details are excluded.",
      "Deduct points for irrelevant information or missing key details.",
    ],
  },
  {
    id: 4,
    title: "Organization and Structure",
    items: [
      "Assess the logical flow and use of headings/subheadings to organize the summary.",
      "Deduct points for disorganized content or poor structure that affects readability.",
    ],
  },
  {
    id: 5,
    title: "Time to Completion",
    items: [
      "Measure the time taken from reading the case details to submission.",
      "Deduct points for taking longer than the ideal time frame.",
    ],
  },
  {
    id: 6,
    title: "Score Calculation",
    items: [
      "Each domain contributes equally to the final score (20 points each).",
      "Sum the scores from all domains to get the final score out of 100.",
    ],
  },
];

import { useEffect, useState } from "react";
import "./MedSynopsispage.css";
import { useNavigate } from "react-router-dom";
import { BackArrowSvg } from "../../components/Svgs/svgsComponents";
import TimedQuiz from "../../components/MedSynopsis/TimedQuiz";
import UnTimedQuiz from "../../components/MedSynopsis/UnTimedQuiz";
import { useQuery } from "@apollo/client";
import { GET_MEDSYNOPSIS_CATEGORIES } from "../../graphql/queries/medSynopsis";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { useDispatch } from "react-redux";
import { quizAction } from "../../state/medSynopsis/quizSlice";
import { GoAlert } from "react-icons/go";

const MedSynopsisPage = () => {
  const [activeTab, setActiveTab] = useState("timed");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, loading, error, refetch } = useQuery(
    GET_MEDSYNOPSIS_CATEGORIES
  );

  useEffect(() => {
    refetch();
  }, []);

  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div className="dashboard-home">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div
            onClick={() => navigate("/dashboard")}
            className="medical-trivia-back-nav "
          >
            {BackArrowSvg()}
          </div>
          <p>Medsynopsis</p>
        </div>
        <button
          className="random-button"
          onClick={() => {
            dispatch(
              quizAction.setQuiz({
                image: "/images/medSynopsis/medrobot.png",
                type: activeTab,
                title: "",
                isRandom: true,
              })
            );
            navigate("/dashboard/med-synopsis/random");
          }}
        >
          Random
        </button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 33,
        }}
      >
        <div
          className="tab-item"
          role="button"
          onClick={() => setActiveTab("timed")}
        >
          <div
            className={`tab-circle ${
              activeTab === "timed" ? "active-tab" : ""
            }`}
          ></div>
          <p>Timed Quiz</p>
        </div>
        <div
          className="tab-item"
          role="button"
          onClick={() => setActiveTab("untimed")}
        >
          <div
            className={`tab-circle ${
              activeTab === "untimed" ? "active-tab" : ""
            }`}
          ></div>
          <p>Untimed quiz</p>
        </div>
      </div>

      {activeTab === "timed" ? (
        <TimedQuiz quizList={data?.getMedsynopsisCategories} />
      ) : (
        <UnTimedQuiz quizList={data?.getMedsynopsisCategories} />
      )}

      {error && (
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <GoAlert size={30} />
          <p>An error occurred while fetching medsynopsis categories</p>
        </div>
      )}

      {/* ai summary */}
      <div style={{ marginBottom: 20 }}>
        <p style={{ fontSize: 18, fontWeight: "600", marginBottom: 10 }}>
          AI Summary
        </p>
        <div
          className="ai-summary"
          role="button"
          onClick={() => navigate("/dashboard/med-synopsis/ai-case-file")}
        >
          <img src="../images/medSynopsis/pen-edit.png" alt="" />
          <p>Upload Case file</p>
        </div>
      </div>

      {/* subscribe modal */}
    </div>
  );
};

export default MedSynopsisPage;

import { useEffect, useState } from "react";
import CountDown from "../../components/MedSynopsis/QuizQuestion/CountDown";
import QuizQuestions from "../../components/MedSynopsis/QuizQuestion/QuizQuestions";
import { useLazyQuery } from "@apollo/client";
import { GET_MEDSYNOPSIS_CASE_BY_CAT } from "../../graphql/queries/medSynopsis";
import { useNavigate, useParams } from "react-router-dom";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { GoAlert } from "react-icons/go";
import "./MedSynopsispage.css";

const QuizQuestionPage = () => {
  const [getMedsynops, { data: catData, loading: gettingCat, error }] =
    useLazyQuery(GET_MEDSYNOPSIS_CASE_BY_CAT, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });
  const [showCountDown, setShowCountDown] = useState(true);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getMedsynops({ variables: { categoryUuid: params.categoryUUID } });
  }, []);

  return (
    <div className="dashboard-home" style={{ flex: 1 }}>
      {gettingCat ? (
        <SpinnerLoader loading={gettingCat} />
      ) : (
        <>
          {error ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
                padding: "2rem",
              }}
            >
              <GoAlert size={30} />
              <p>
                {error.message ||
                  "We could not get case! Please try again in few mins"}
              </p>
              <div className="btn" onClick={() => navigate(-1)}>
                Go Back
              </div>
            </div>
          ) : showCountDown ? (
            <CountDown
              onClose={() => setShowCountDown(false)}
              quizData={catData?.getMedsynopsisCaseByCat}
            />
          ) : (
            <QuizQuestions quizData={catData?.getMedsynopsisCaseByCat} />
          )}
        </>
      )}
    </div>
  );
};

export default QuizQuestionPage;

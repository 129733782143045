import React from "react";

const FeedbackImage: React.FC<{
  size?: number;
  imageUrl: string;
}> = ({ size = 140, imageUrl }) => {
  // Generate sunflower-like petal border
  const generateSunflowerPath = () => {
    const points = [];
    const petals = 16;
    const baseRadius = size * 0.42;
    const petalOuterRadius = baseRadius * 1.15;

    for (let i = 0; i < petals; i++) {
      // Use `< petals` instead of `<= petals`
      const angle = (i / petals) * Math.PI * 2;
      const nextAngle = ((i + 1) / petals) * Math.PI * 2;

      const centerX = size / 2;
      const centerY = size / 2;

      const startX = centerX + baseRadius * Math.cos(angle);
      const startY = centerY + baseRadius * Math.sin(angle);

      const ctrl1X =
        centerX + petalOuterRadius * Math.cos(angle + Math.PI / (petals * 2));
      const ctrl1Y =
        centerY + petalOuterRadius * Math.sin(angle + Math.PI / (petals * 2));
      const ctrl2X =
        centerX +
        petalOuterRadius * Math.cos(nextAngle - Math.PI / (petals * 2));
      const ctrl2Y =
        centerY +
        petalOuterRadius * Math.sin(nextAngle - Math.PI / (petals * 2));

      const endX = centerX + baseRadius * Math.cos(nextAngle);
      const endY = centerY + baseRadius * Math.sin(nextAngle);

      if (i === 0) {
        points.push(`M ${startX} ${startY}`);
      }

      points.push(
        `C ${ctrl1X} ${ctrl1Y}, ${ctrl2X} ${ctrl2Y}, ${endX} ${endY}`
      );
    }

    points.push("Z"); // Close the path properly
    return points.join(" ");
  };

  const pathD = generateSunflowerPath();

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        width: size,
        height: size,
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <defs>
          <clipPath id="sunflower-clip">
            <path d={pathD} />
          </clipPath>
        </defs>

        {/* profile image url */}
        <image
          href={imageUrl}
          width={size}
          height={size}
          clipPath="url(#sunflower-clip)"
          preserveAspectRatio="xMidYMid slice"
        />

        <path
          d={pathD}
          fill="none"
          stroke="#B9EDCE"
          strokeWidth="3"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default FeedbackImage;

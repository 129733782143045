import React, { useEffect, useState } from "react";
import "./LargeCountDown.css";

const LargeCountDown = ({
  duration,
  timeLeft,
  setTimeLeft,
  pauseTime,
}: {
  duration: number;
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
  pauseTime: boolean;
}) => {
  const [radius, setRadius] = useState(120);
  const [fontSize, setFontSize] = useState(60);
  const [strokeWidth, setStrokeWidth] = useState(18);

  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    // Adjust dimensions for mobile screens
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setRadius(80);
        setFontSize(40);
        setStrokeWidth(12);
      } else {
        setRadius(120);
        setFontSize(60);
        setStrokeWidth(18);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (timeLeft > 0 && !pauseTime) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [pauseTime, setTimeLeft, timeLeft]);

  const progress = (1 - timeLeft / duration) * circumference;

  return (
    <div className="progress-container">
      <svg className="progress-ring" width={radius * 3} height={radius * 3}>
        {/* Gradient Definition */}
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#2F8BF8CC" />
            <stop offset="50%" stopColor="#417AEDCC" />
            <stop offset="100%" stopColor="#9045A6CC" />
          </linearGradient>
        </defs>

        {/* Circle with gradient stroke */}
        <circle
          className="progress-ring__circle"
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={radius * 1.5}
          cy={radius * 1.5}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: progress,
          }}
        />
      </svg>
      <div className="progress-text" style={{ fontSize: `${fontSize}px` }}>
        {timeLeft}s
      </div>
    </div>
  );
};

export default LargeCountDown;

import { createSlice } from "@reduxjs/toolkit";
import { MedSummaryScoreProps } from "../../types/Medsynopsis";

type FeedbackState = {
  feedback: MedSummaryScoreProps | null;
};

const initialState: FeedbackState = { feedback: null };

const feedbackSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setFeedback(state, action) {
      state.feedback = action.payload.feedback;
    },
  },
});

export const feedbackAction = feedbackSlice.actions;
export default feedbackSlice.reducer;

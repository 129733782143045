import React from "react";
import { BackArrowSvg } from "../../components/Svgs/svgsComponents";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { IoMenuSharp } from "react-icons/io5";
import MedSynopsisFeedback from "../../components/MedSynopsis/Feedback/MedSynopsisFeedback";

const MedSynopsisFeedbackPage = () => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-home">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div>
            <IoMenuSharp size={24} />
          </div>
          <p style={{ fontSize: 18, fontWeight: 600 }}>Feedback</p>
        </div>

        <div
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
          role="button"
        >
          <IoMdClose size={26} />
        </div>
      </div>

      <MedSynopsisFeedback />
    </div>
  );
};

export default MedSynopsisFeedbackPage;

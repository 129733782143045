import React, { useState } from "react";
import { CaseFileResponse } from "../../types/Medsynopsis";
import AiSummaryModal from "../../components/MedSynopsis/AiCaseFile/AiSummaryModal";
import { IoMdClose, IoMdSend } from "react-icons/io";
import Modal from "../../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { UPLOAD_MEDSYNOPSIS_CASEFILE } from "../../graphql/queries/medSynopsis";
import { ImSpinner2 } from "react-icons/im";
import { IoCopy, IoCopyOutline } from "react-icons/io5";

const AiCaseFilePage = () => {
  const [showAiModal, setShowAiModal] = useState(true);
  const [subscribeMessage, setSubscribeMessage] = useState("");
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [aiResponse, setAiResponse] = useState<CaseFileResponse | null>(null);
  const [chatHistory, setChatHistory] = useState<
    { text: string; type: string; id: string }[]
  >([]);
  const [copied, setCopied] = useState<string | null>(null);
  const [uploadCaseFile, { loading: uploadingFile, error }] = useMutation(
    UPLOAD_MEDSYNOPSIS_CASEFILE
  );
  const [imgFile, setImgFile] = useState<File | null>(null);
  const navigate = useNavigate();

  const handleCopy = (chat: { text: string; type: string; id: string }) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(chat.text, "text/html");
    navigator.clipboard.writeText(`${doc.body.textContent}`);
    setCopied(chat.id);
    setTimeout(() => {
      setCopied(null);
    }, 1500);
  };

  const handleSubmit = async () => {
    if (prompt.trim() !== "") {
      setChatHistory((prev) => [
        ...prev,
        { text: prompt, type: "out", id: `${new Date()}` },
      ]);
      setPrompt("");
    }

    try {
      const response = await uploadCaseFile({
        variables: {
          file: imgFile || null,
          userPrompt: prompt || "",
          messageId: aiResponse?.messageId || null,
          threadId: aiResponse?.threadId || null,
        },
      });
      setPrompt("");
      setAiResponse(response.data.addUserMedsynopsisCase);
      setChatHistory((prev) => [
        ...prev,
        {
          text: response.data.addUserMedsynopsisCase.userSummary,
          type: "in",
          id: `${new Date()}`,
        },
      ]);
    } catch (err: any) {
      if (err.message === "INSUFFICIENT_CREDIT") {
        setSubscribeMessage(
          "Your current credit balance for AI services has been exhausted. To continue using our AI features, please upgrade your account to receive additional credits."
        );
        setShowSubscribeModal(true);
        return;
      } else if (err.message === "INTRO_INSUFFICIENT_CREDIT") {
        setSubscribeMessage(
          "Experience our AI features at no cost by signing up for a free trial, which includes complimentary AI credits"
        );
        setShowSubscribeModal(true);
        return;
      } else {
        toast.error(
          `${err.message || "Something went wrong! Please try again."}`
        );
      }
    }
  };

  return (
    <div
      // className="dashboard-home"
      style={{
        height: "100vh",
        marginBottom: 15,
      }}
    >
      <div
        style={{
          flex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <div
          style={{
            overflowY: "auto",
            flex: 1,
          }}
        >
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              flexDirection: "column",
              gap: 30,
            }}
          >
            {imgFile && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  border: "1px solid #7b7b7b",
                  padding: 10,
                  borderRadius: 10,
                  maxWidth: 300,
                  marginTop: 20,
                  alignSelf: "flex-end",
                  marginRight: 20,
                }}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setImgFile(null);
                    setShowAiModal(true);
                    setChatHistory([]);
                    setAiResponse(null);
                  }}
                  role="button"
                  className="file-close-button"
                >
                  <IoMdClose size={16} />
                </div>
                <p style={{ margin: 0 }}>{imgFile.name}</p>
              </div>
            )}
            {chatHistory.map((chat) => (
              <li
                key={chat.id}
                style={{
                  alignSelf: chat.type === "in" ? "flex-start" : "flex-end",
                  border: "1px solid #7b7b7b",
                  padding: 10,
                  borderRadius: 10,
                  backgroundColor: chat.type === "in" ? "#349aff61" : "#349AFF",
                  maxWidth: "80%",
                  position: "relative",
                  marginBottom: chat.text === "in" ? 100 : 0,
                }}
              >
                <p>{chat.text}</p>
                {chat.type === "in" && (
                  <div
                    role="button"
                    onClick={() => handleCopy(chat)}
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: -25,
                      cursor: "pointer",
                    }}
                  >
                    {copied === chat.id ? <IoCopy /> : <IoCopyOutline />}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* input */}
        <div
          style={{
            padding: 10,
            // border: "1px solid #7b7b7b",
            display: "flex",
          }}
        >
          {/* input */}
          <div
            className="input-container"
            style={{ marginTop: 10, width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
                marginTop: "auto",
                width: "100%",
              }}
            >
              <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  color: "white",
                  outline: "none",
                  border: "none",
                }}
                placeholder="Enter your prompt here"
              />
              <div
                role="button"
                style={{ cursor: "pointer", display: "flex", width: 20 }}
                onClick={() => !uploadingFile && handleSubmit()}
              >
                {uploadingFile ? (
                  <ImSpinner2 className="loading-spinner" />
                ) : (
                  <IoMdSend size={24} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAiModal && (
        <AiSummaryModal
          onClose={() => setShowAiModal(false)}
          setSubscribeMessage={setSubscribeMessage}
          setShowSubscribeModal={setShowSubscribeModal}
          setAiResponse={setAiResponse}
          setImgFile={setImgFile}
          imgFile={imgFile}
          setChatHistory={setChatHistory}
        />
      )}

      {/* subscribe modal */}
      {showSubscribeModal && (
        <Modal extraFunc={() => setShowSubscribeModal(false)}>
          <div className="ai-modal">
            <div
              role="button"
              onClick={() => setShowSubscribeModal(false)}
              style={{
                position: "absolute",
                top: 10,
                right: 20,
                cursor: "pointer",
              }}
            >
              <IoMdClose size={20} />
            </div>
            <div>
              <h5>Insufficient credits</h5>
              <p>{subscribeMessage}</p>
              <div
                className="subscribe-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSubscribeModal(false);
                  navigate("/dashboard/settings");
                }}
              >
                Subscribe Now
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AiCaseFilePage;

/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              gap: 20,
            }}
          >
            <div className="answer-container">
              <div
                dangerouslySetInnerHTML={{ __html: aiResponse.userSummary }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <div
                className="copy-response-button"
                onClick={() => {
                  setShowAnswer(false);
                  setPrompt("");
                  setImgFile(null);
                }}
              >
                Enter a new prompt
              </div>
              <div
                className="copy-response-button"
                onClick={handleCopy}
                style={{ backgroundColor: "#349AFF" }}
              >
                {copied ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                      flexDirection: "row",
                    }}
                  >
                    <IoMdCheckmark size={18} />
                    Copied
                  </div>
                ) : (
                  "Copy Response"
                )}
              </div>
            </div>
          </div>
       */

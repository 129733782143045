import React, { useState } from "react";
import Modal from "../../Modal/Modal";
import "./AIFeedback.css";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../state";
import { UserI } from "../../../types/AuthResponse";

const AIFeedback: React.FC<{ summary?: string }> = ({ summary }) => {
  const [showModal, setShowModal] = useState(false);
  const userData = useSelector((state: RootState) => state.user) as UserI;

  const navigate = useNavigate();

  return (
    <div style={{ width: "100%", height: 300 }}>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {!userData?.subscription?.tokenBalance ||
        userData?.subscription?.tokenBalance < 0 ? (
          <img
            src="/images/medSynopsis/ai-feedback-bg.png"
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <div className="ai-feedback-summary">
            <p>{summary}</p>
          </div>
        )}
        {!userData?.subscription?.tokenBalance ||
          (userData?.subscription?.tokenBalance < 0 && (
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
              }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              <p style={{ fontSize: 16, textAlign: "center" }}>Reveal Result</p>
            </div>
          ))}
      </div>

      {/* subscribe modal */}
      {showModal && (
        <Modal extraFunc={() => setShowModal(false)}>
          <div className="ai-modal">
            <div
              role="button"
              onClick={() => setShowModal(false)}
              style={{
                position: "absolute",
                top: 10,
                right: 20,
                cursor: "pointer",
              }}
            >
              <IoMdClose size={20} />
            </div>
            <div>
              <h5>Subscribe</h5>
              <p>Subscribe to access this feature and many exciting offers </p>
              <div
                className="subscribe-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(false);
                  navigate("/dashboard/settings");
                }}
              >
                Subscribe Now
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AIFeedback;

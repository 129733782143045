import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomePage from "./pages/HomePage/HomePage";
import ContactUs from "./pages/ContactUs/Contactus";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Pricing from "./pages/Pricing/Pricing";
import FAQ from "./pages/FAQ/FAQ";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import { HelmetProvider } from "react-helmet-async";
import "./i18n";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import DashboardLayout from "./layouts/DashboardLayout/DashboardLayout";
import DashboardHome from "./pages/DashboardHome/DashboardHome";
import DashboardCreate from "./pages/DashboardCreate/DashboardCreate";

import Trivia from "./pages/Trivia/Trivia";
import { useAuth } from "./context/AuthContext";
import DashboardQuestion from "./pages/DashboardQuestion/DashboardQuestion";
import QuestionBankQuiz from "./pages/QuestionBankQuiz/QuestionBankQuiz";
import DashboardAddQuestion from "./pages/DashboardAddQuestion/DashboardAddQuestion";
import AICreateQuestion from "./pages/AICreateQuestion/AICreateQuestion";
import ForgetPassowrd from "./pages/ForgetPassowrd/ForgetPassowrd";
import ResetPassord from "./pages/NewPassword/ResetPassord";
import DashboardSettings from "./pages/DashboardSettings/DashboardSettings";
import MultiplerCodeView from "./pages/MultiplerCodeView/MultiplerCodeView";
import MultiplayerCodeGenerated from "./pages/MultiplayerCodeGenerated/MultiplayerCodeGenerated";
import QRCodeRedirection from "./pages/QRCodeRedirection/QRCodeRedirection";
import { MultiplayerGameCode } from "./pages/MultiplaerGameCode/MultiplayerGameCode";
import MultiplayerWaiting from "./pages/MultiplayerWaiting/MultiplayerWaiting";
import MultiplayerQuestionBank from "./pages/MultiplayerQuestionBank/MultiplayerQuestionBank";
import MultiplayerTrivia from "./pages/MultiplayerTrivia/MultiplayerTrivia";
import SubTriviaCategory from "./pages/SubTriviaCategory/SubTriviaCategory";

// medsynopsis pages
import MedSynopsisPage from "./pages/MedSynopsisPages/MedSynopsisPage";
import QuizQuestionPage from "./pages/MedSynopsisPages/QuizQuestionPage";
import MedSynopsisFeedbackPage from "./pages/MedSynopsisPages/MedSynopsisFeedbackPage";
import RandomQuizQuestionPage from "./pages/MedSynopsisPages/RandomQuizQuestionPage";
import AiCaseFilePage from "./pages/MedSynopsisPages/AiCaseFilePage";

function App() {
  const location = useLocation();

  const { authenticateUser } = useAuth();

  useEffect(() => {
    window.scroll(0, 0);

    const token = localStorage.getItem("token");
    if (token) {
      authenticateUser();
    }
  }, [authenticateUser, location]);

  return (
    <section>
      <HelmetProvider>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forget-password" element={<ForgetPassowrd />} />
          <Route path="/reset-password" element={<ResetPassord />} />
          <Route path="/qr-redirect/:code?" element={<QRCodeRedirection />} />

          {/* Protected Routes In dashboard */}
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardHome />} />
            <Route path="create/:quizUuid?" element={<DashboardCreate />} />
            <Route
              path=":triviaType/:quizUUID"
              element={<SubTriviaCategory />}
            />
            <Route path="add-questions/ai" element={<AICreateQuestion />} />

            <Route path="settings" element={<DashboardSettings />} />

            <Route path="multiplyer" element={<MultiplerCodeView />} />

            <Route
              path="multiplyer-invited/:code?"
              element={<MultiplayerGameCode />}
            />
            <Route path="med-synopsis" element={<MedSynopsisPage />} />
            <Route
              path="med-synopsis/ai-case-file"
              element={<AiCaseFilePage />}
            />
            <Route
              path="med-synopsis/:categoryUUID"
              element={<QuizQuestionPage />}
            />
            <Route
              path="med-synopsis/random"
              element={<RandomQuizQuestionPage />}
            />
            <Route
              path="med-synopsis/:quizUUID/feedback"
              element={<MedSynopsisFeedbackPage />}
            />
          </Route>

          {/* Other protected routes */}
          <Route
            path="trivia/:triviaType?/:quizUUID/:id?"
            element={<Trivia />}
          />
          <Route path="/question-bank" element={<DashboardQuestion />} />
          <Route
            path="/question-bank/quiz/:quizUUID"
            element={<QuestionBankQuiz />}
          />
          <Route
            path="/add-questions/:quizUuid?"
            element={<DashboardAddQuestion />}
          />
          <Route
            path="/multiplayer-generate-code/:code?"
            element={<MultiplayerCodeGenerated />}
          />
          <Route path="/multiplayer-quiz" element={<MultiplayerTrivia />} />

          <Route path="/multiplayer-waiting" element={<MultiplayerWaiting />} />
          <Route
            path="/multiplayer-bank-selection"
            element={<MultiplayerQuestionBank />}
          />
        </Routes>
      </HelmetProvider>
    </section>
  );
}

export default App;

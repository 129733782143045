import { useSelector } from "react-redux";
import { RootState } from "../../../state";
import "./QuizQuestion.css";
import { useEffect, useState } from "react";
import LargeCountDown from "./LargeCountDown";

const CountDown = ({
  onClose,
  quizData,
}: {
  onClose: () => void;
  quizData: any;
}) => {
  const [timeLeft, setTimeLeft] = useState(5);
  const [pauseTime, setPauseTime] = useState(false);
  const quizInfo = useSelector((state: RootState) => state.medSynopsisQuiz);

  useEffect(() => {
    if (timeLeft === 0) {
      onClose();
    }
  }, [timeLeft]);

  return (
    <div className="countdown-container">
      <h4>{quizData?.categoryTitle || quizData?.categoryName}</h4>
      <div style={{ textAlign: "center" }}>
        <p>Question:</p>
        <small>{quizData?.question || quizData?.caseTitle}</small>
      </div>

      <img
        src={quizInfo?.image || "/images/medSynopsis/medrobot.png"}
        alt={quizInfo.quizTitle}
      />

      <div>
        <LargeCountDown
          duration={5}
          timeLeft={timeLeft}
          setTimeLeft={setTimeLeft}
          pauseTime={pauseTime}
        />
      </div>
    </div>
  );
};

export default CountDown;

import { createSlice } from "@reduxjs/toolkit";

const quizSlice = createSlice({
  name: "user",
  initialState: { quizTitle: "", image: "", type: "", isRandom: false },
  reducers: {
    setQuiz(state, action) {
      state.quizTitle = action.payload.title;
      state.image = action.payload.image;
      state.type = action.payload.type;
      state.isRandom = action.payload.isRandom;
    },
  },
});

export const quizAction = quizSlice.actions;
export default quizSlice.reducer;

import { useEffect, useState } from "react";
import CountDown from "../../components/MedSynopsis/QuizQuestion/CountDown";
import QuizQuestions from "../../components/MedSynopsis/QuizQuestion/QuizQuestions";
import { useMutation } from "@apollo/client";
import { GET_RANDOM_CASE } from "../../graphql/queries/medSynopsis";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { GoAlert } from "react-icons/go";
import "./MedSynopsispage.css";
import { RandomCaseResponse } from "../../types/Medsynopsis";
import { toast } from "react-toastify";

const RandomQuizQuestionPage = () => {
  const [showCountDown, setShowCountDown] = useState(true);
  const [getRandomCase, { loading: loadingRandomCase, error }] =
    useMutation(GET_RANDOM_CASE);
  const [randomCase, setRandomCase] = useState<RandomCaseResponse | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    generateRandomCase();
  }, []);

  const generateRandomCase = async () => {
    try {
      const data = await getRandomCase();
      setRandomCase(data.data.generateRandomMedSynopsisQuestions);
    } catch (err: any) {
      toast.error(
        `${
          err?.message || "We could not get case! Please try again in few mins"
        }`
      );
    }
  };

  return (
    <div className="dashboard-home" style={{ flex: 1 }}>
      {loadingRandomCase ? (
        <SpinnerLoader loading={loadingRandomCase} />
      ) : (
        <>
          {error ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
                padding: "2rem",
              }}
            >
              <GoAlert size={30} />
              <p>
                {error?.message ||
                  "We could not get case! Please try again in few mins"}
              </p>
              <div className="btn" onClick={() => navigate(-1)}>
                Go Back
              </div>
            </div>
          ) : showCountDown ? (
            <CountDown
              onClose={() => setShowCountDown(false)}
              quizData={randomCase}
            />
          ) : (
            <QuizQuestions quizData={randomCase} />
          )}
        </>
      )}
    </div>
  );
};

export default RandomQuizQuestionPage;

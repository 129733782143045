import { useEffect, useState } from "react";
import "./DashboardHome.css";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CATEGORY } from "../../graphql/queries/getDashboardCategory";
import { CategoryI, GetCategoryI } from "../../types/GetCategory";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Modal from "../../components/Modal/Modal";
import { CloseSvg } from "../../components/Svgs/svgsComponents";

// Add name of card that should not show up oon the UI
// As the main card display might not yet be avialable for web
// But only on mobile
const cardsToRemove = ["Case Recall", "Open Ended Question"];

const DashboardHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeFeature, setActiveFeature] = useState("1");
  const [activeFilter, setActiveFilter] = useState("1");
  const [filteredCategory, setFilteredCategory] = useState<CategoryI[] | []>(
    []
  );
  // const { emit, on } = useSocketContext();

  const [showMutiplyer, setShowMultipler] = useState(false);

  const [dashCategory, setDashCategory] = useState<CategoryI[] | []>([]);

  // Get dashboard category
  const { loading, error, data, refetch } =
    useQuery<GetCategoryI>(GET_CATEGORY);

  const handleRouteLink = (category: string, quizUUID: string) => {
    switch (category) {
      case "MedSynopsis":
        navigate(`/dashboard/med-synopsis`);
        return;
      case "General Trivia":
        navigate(`/trivia/${quizUUID}`);
        return;
      case "Medical Trivia":
        navigate(`/dashboard/medical-trivia/${quizUUID}`);
        return;
      case "My Question Bank":
        navigate(`/question-bank`);
        return;
      case "Basic Sciences":
        navigate(`/dashboard/basic-sciences/${quizUUID}`);
        return;
      default:
        navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (data) {
      const newData = Object.keys(data.getCategory?.categories)
        .map((key) => data.getCategory?.categories[key])
        .reverse();

      const medSynopsisData = {
        category: "MedSynopsis",
        coverImage: "/images/medSynopsis/med-robot.png",
        quizUUID: "",
        totalNumber: 0,
      };

      setDashCategory([...newData, medSynopsisData]);
      setFilteredCategory([...newData, medSynopsisData]);
    }

    if (error) {
      toast.error(error?.message || "Unexpected errorr occured");
    }
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, refetch]);

  // Spinner Loads
  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div className="dashboard-home">
      {/* featured slide */}
      <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
        <p style={{ fontSize: 24, fontWeight: "600" }}>Featured</p>
        <ul className="list">
          {featuresList.map((item, index) => {
            return (
              <li
                key={item.id}
                className={`feature-item ${
                  activeFeature === item.id ? "feature-item-active" : ""
                }`}
                style={{ backgroundColor: `${item.color}` }}
                onClick={() => setActiveFeature(item.id)}
              >
                <div className="gradient-container">
                  <img src={item.gradient} alt="gradient" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <div className="text-container">
                    <h5>{item.title}</h5>
                    <p>{item.decription}</p>
                  </div>
                  <div className="gradient-btn-wrapper">
                    <button
                      onClick={() => {
                        item.title === "MedSynopsis"
                          ? navigate(item.route)
                          : toast.success("Coming Soon!");
                      }}
                      className={index % 2 === 0 ? "white-btn" : "gradient-btn"}
                    >
                      {item.buttonText}
                    </button>
                  </div>
                </div>

                <div className="feature-img-container">
                  <img src={item.image} alt={item.decription} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {/* filters */}
      <ul
        style={{
          display: "flex",
          gap: 20,
          listStyle: "none",
        }}
        className="list"
      >
        {filterList.map((item) => {
          return (
            <li
              key={item.id}
              onClick={() => {
                setActiveFilter(item.id);
                setFilteredCategory(
                  item.categories.includes("all")
                    ? dashCategory
                    : dashCategory.filter((category) =>
                        item.categories.includes(category.category)
                      )
                );
              }}
              className={`${
                activeFilter === item.id
                  ? "filter-item-active gradiat-border"
                  : "filter-item"
              } `}
              style={{ maxWidth: 350, textWrap: "nowrap" }}
            >
              {item.title}
            </li>
          );
        })}
      </ul>

      {/* quiz */}
      <div className="dashboard-home-cards">
        {filteredCategory.length === 0 && (
          <div
            style={{
              width: "100%",
              marginTop: 100,
              marginBottom: 100,
            }}
          >
            <p style={{ textAlign: "center" }}>
              No quiz available for this category! Please pull to refresh
            </p>
          </div>
        )}
        {filteredCategory.length > 0 &&
          filteredCategory.map(
            ({ category, coverImage, totalNumber, quizUUID }, index) =>
              !cardsToRemove.includes(category) && (
                <div
                  className="dashboard-home-card"
                  key={index}
                  onClick={() => {
                    handleRouteLink(category, quizUUID);
                  }}
                >
                  <div className="dashboard-home-card__img">
                    <img src={coverImage} alt={`${category} banner`} />
                  </div>

                  <div className="dashboard-home-card__content">
                    <h3>{category} </h3>
                    {category !== "MedSynopsis" && (
                      <p>
                        {totalNumber}{" "}
                        {totalNumber > 1 ? "questions" : "question"}{" "}
                      </p>
                    )}
                  </div>
                </div>
              )
          )}
      </div>
      <div className="dashboard-home-btn">
        <button className="not-for-current-sprint auth-btn">
          Untimed Quiz
        </button>
        <button className="btn-border " onClick={() => setShowMultipler(true)}>
          Multiplayer
        </button>
      </div>

      {/* {error && <p>{error.message}</p>} */}

      {showMutiplyer && (
        <Modal
          extraFunc={() => {
            setShowMultipler(false);
          }}
        >
          <div
            className="modal-multiplayer "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="modal-cancel"
              onClick={() => setShowMultipler(false)}
            >
              {CloseSvg()}
            </div>
            <h4>Select your preferred mode</h4>

            <p
              onClick={() => {
                setShowMultipler(false);
                navigate("/dashboard/multiplyer");
              }}
            >
              Friends
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DashboardHome;

const featuresList = [
  // {
  //   id: "1",
  //   title: "AI Presentation",
  //   decription: "AI is here to help with your slide",
  //   image: "./images/ai-presentation.png",
  //   color: "#10108C",
  //   route: "",
  //   buttonText: "Lets Present",
  //   gradient: "./images/medsy-gradient.png",
  // },
  // {
  //   id: "2",
  //   title: "Simulation",
  //   decription: "Engage in real live situations through our chat simualtion",
  //   image: "./images/simulation-bot.png",
  //   color: "#21273D",
  //   route: "",
  //   buttonText: "Chat",
  //   gradient: "./images/medsy-gradient.png",
  // },
  {
    id: "3",
    title: "MedSynopsis",
    decription: "Sharpen your clinical summaries today!",
    image: "./images/ai-presentation.png",
    color: "#349AFF",
    route: "/dashboard/med-synopsis",
    buttonText: "Play",
    gradient: "./images/medsy-gradient.png",
  },
];

const filterList = [
  {
    id: "1",
    title: "All",
    categories: ["all"],
  },
  // {
  //   id: "2",
  //   title: "Open ended questions",
  // },
  {
    id: "3",
    title: "Clinical skills",
    categories: ["Case recall", "MedSynopsis", "Case Presentation"],
    // categories: ["Case recall", "MedSynopsis", "Case Presentation"],
  },
  {
    id: "4",
    title: "Multiple choice questions",
    categories: [
      "Medical Trivia",
      "General Trivia",
      "Clinical Sciences",
      "Basic Sciences",
      "My Question Bank",
      "Instant Quiz",
    ],
  },
];

// Clinical Skills: Case recall, MedSynopsis, Case Presentation
// Open Ended Questions: DX quest, Med Match
// Multiple Choice Questions: Basic sciences, Medical Trviva, General Trivia, Clinical sciences, My question bank, instant quiz

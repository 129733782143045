import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from "react";
import Modal from "../../Modal/Modal";
import "../Medsynopsis.css";
import { IoMdClose, IoMdSend } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import { UPLOAD_MEDSYNOPSIS_CASEFILE } from "../../../graphql/queries/medSynopsis";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";
import { CaseFileResponse } from "../../../types/Medsynopsis";
import { useNavigate } from "react-router-dom";

const AiSummaryModal: React.FC<{
  onClose: () => void;
  setSubscribeMessage: Dispatch<SetStateAction<string>>;
  setShowSubscribeModal: Dispatch<SetStateAction<boolean>>;
  setAiResponse: Dispatch<SetStateAction<CaseFileResponse | null>>;
  imgFile: File | null;
  setImgFile: Dispatch<SetStateAction<File | null>>;
  setChatHistory: Dispatch<
    SetStateAction<{ text: string; type: string; id: string }[]>
  >;
}> = ({
  onClose,
  setSubscribeMessage,
  setShowSubscribeModal,
  setAiResponse,
  imgFile,
  setImgFile,
  setChatHistory,
}) => {
  const [prompt, setPrompt] = useState("");
  const imageRef = useRef<HTMLInputElement | null>(null);
  const [uploadCaseFile, { loading: uploadingFile, error }] = useMutation(
    UPLOAD_MEDSYNOPSIS_CASEFILE
  );
  const navigate = useNavigate();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImgFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (!imgFile) {
      toast.error("Please select a file to upload");
      return;
    }

    if (prompt.trim() !== "") {
      setChatHistory((prev) => [
        ...prev,
        { text: prompt, type: "out", id: `${new Date()}` },
      ]);
    }

    try {
      const response = await uploadCaseFile({
        variables: {
          file: imgFile || null,
          userPrompt: prompt || "",
          messageId: null,
          threadId: null,
        },
      });
      setPrompt("");
      setAiResponse(response.data.addUserMedsynopsisCase);
      setChatHistory((prev) => [
        ...prev,
        {
          text: response.data.addUserMedsynopsisCase.userSummary,
          type: "in",
          id: `${new Date()}`,
        },
      ]);

      if (response.data.addUserMedsynopsisCase.userSummary === "") {
        toast.error("No summary found");
        return;
      }
      onClose();
    } catch (err: any) {
      if (err.message === "INSUFFICIENT_CREDIT") {
        setSubscribeMessage(
          "Your current credit balance for AI services has been exhausted. To continue using our AI features, please upgrade your account to receive additional credits."
        );
        onClose();
        setShowSubscribeModal(true);
        return;
      } else if (err.message === "INTRO_INSUFFICIENT_CREDIT") {
        setSubscribeMessage(
          "Experience our AI features at no cost by signing up for a free trial, which includes complimentary AI credits"
        );
        onClose();
        setShowSubscribeModal(true);
        return;
      } else {
        toast.error(`${err.message || "Something went wrong"}`);
      }
    }
  };

  return (
    <Modal extraFunc={() => console.log()}>
      <div className="ai-modal">
        <div
          role="button"
          onClick={() => navigate(-1)}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 10,
            top: 10,
          }}
        >
          <IoMdClose size={20} />
        </div>
        <p
          style={{
            fontSize: 16,
            fontWeight: "700",
            alignSelf: "center",
          }}
        >
          Upload file
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            role="button"
            onClick={() => imageRef.current?.click()}
            className="file-container"
          >
            {imgFile ? (
              <div className="file-name-container">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setImgFile(null);
                  }}
                  role="button"
                  className="file-close-button"
                >
                  <IoMdClose size={16} />
                </div>
                <p style={{ margin: 0 }}>{imgFile.name}</p>
              </div>
            ) : (
              <IoCloudUploadOutline size={34} />
            )}
            <input
              type="file"
              onChange={handleFileChange}
              ref={imageRef}
              style={{ display: "none" }}
              accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
            />
          </div>

          {/* input */}
          <div className="input-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
                marginTop: "auto",
                width: "100%",
              }}
            >
              <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  color: "white",
                  outline: "none",
                  border: "none",
                }}
                placeholder="Enter your prompt here"
              />
              <div
                role="button"
                style={{ cursor: "pointer", display: "flex", width: 10 }}
                onClick={() => !uploadingFile && handleSubmit()}
              >
                {uploadingFile ? (
                  <ImSpinner2 className="loading-spinner" />
                ) : (
                  <IoMdSend size={24} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AiSummaryModal;

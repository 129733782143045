import { gql } from "@apollo/client";

export const GET_MEDSYNOPSIS_CATEGORIES = gql`
  query GetMedsynopsisCategories {
    getMedsynopsisCategories {
      categoryUUID
      coverImage
      createdAt
      description
      title
      updatedAt
    }
  }
`;

export const UPLOAD_MEDSYNOPSIS_CASEFILE = gql`
  mutation AddUserMedsynopsisCase(
    $file: Upload
    $userPrompt: String
    $threadId: String
  ) {
    addUserMedsynopsisCase(
      file: $file
      userPrompt: $userPrompt
      threadId: $threadId
    ) {
      caseContent
      caseID
      messageId
      threadId
      userSummary
    }
  }
`;

export const GET_MEDSYNOPSIS_CASE_BY_CAT = gql`
  query GetMedsynopsisCaseByCat($categoryUuid: String!) {
    getMedsynopsisCaseByCat(categoryUUID: $categoryUuid) {
      caseContent
      caseTitle
      caseUUID
      categoryTitle
      categoryUUID
      question
    }
  }
`;

export const GENERATE_SUMMARY_SCORE = gql`
  mutation GenerateMedCaseSummaryScore(
    $medSummaryScoreInput: MedScoreRecordInput!
  ) {
    generateMedCaseSummaryScore(medSummaryScoreInput: $medSummaryScoreInput) {
      aIFeedback
      caseAISummary
      chatThreadId
      score
      scoreSummary {
        accuracy
        clarityConciseness
        completionTime
        organizationStructure
        relevance
      }
      userSummary
    }
  }
`;

export const GET_RANDOM_CASE = gql`
  mutation GenerateRandomMedSynopsisQuestions {
    generateRandomMedSynopsisQuestions {
      caseContent
      caseTitle
      caseUUID
      categoryName
      categoryUUID
    }
  }
`;

import React, { useEffect, useState } from "react";

import "./SubscriptionPayment.css";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ALL_PRODUCTS } from "../../graphql/queries/getAllProducts";
import { toast } from "react-toastify";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import {
  ProductAmountI,
  ProductsI,
  ProductStripeI,
} from "../../types/GetProducts";
import { separateCamelCase } from "../../Utils/splitCamelCaseWord";
import { CheckSvg } from "../../components/Svgs/svgsComponents";
import StripePayment from "../../components/StripePayment/StripePayment";

const SubscriptionPayment = ({
  isSubScribeInView,
  isTrail,
}: {
  isSubScribeInView?: boolean;
  isTrail?: boolean;
}) => {
  const [isMonthlySubSelected, setIsMonthlySubSelected] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState("pro");
  const [openSubFeatures, setOpenSubFeatures] = useState<boolean>(
    isSubScribeInView === undefined ? true : isSubScribeInView
  );

  const [productId, setProductId] = useState<number | string>(0);

  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);

  const [subscriptionPrice, setSubscriptionPrice] = useState<
    (string | number)[][]
  >([]);

  const [subscriptionFeatures, setSubscriptionFeatures] = useState<
    (
      | string
      | number
      | boolean
      | ProductAmountI
      | ProductStripeI
      | undefined
    )[][]
  >([]);

  const [stripeProPlanProductData, setStripeProPlanProductData] =
    useState<ProductStripeI | null>(null);

  const [stripePremiumPlanProductData, setStripePremiumPlanProductData] =
    useState<ProductStripeI | null>(null);

  // Get all products
  // const [getAllProducts, { data, loading, error }] = useLazyQuery(GET_ALL_PRODUCTS);

  // test
  const { data, loading, error } = useQuery(GET_ALL_PRODUCTS);

  const handlesetProductId = (currentPlan: string, isMonthly?: boolean) => {
    // Get perrtiicular plan data
    const payValueObject: ProductStripeI | null =
      currentPlan === "pro"
        ? stripeProPlanProductData
        : stripePremiumPlanProductData;

    // Set key for monthly and yearly  product ID
    const planRange = isMonthly ? "monthlyPriceId" : "yearlyPriceId";

    const productPriceID = payValueObject![planRange as keyof ProductStripeI];

    // setSubscriptionId(payValueObject?.stripeProductId);
    setProductId(productPriceID!);
  };

  useEffect(() => {
    if (data) {
      const resData = data?.getAllProduct;

      // To hold price data
      let prices: (string | number)[][] = [[], []];

      resData.forEach((item: ProductsI, index: number) => {
        prices[0][index] =
          item?.amount?.monthly === 0 ? "Free" : item?.amount?.monthly;
        prices[1][index] =
          item?.amount?.yearly === 0 ? "Free" : item?.amount?.yearly;
      });

      setSubscriptionPrice(prices);

      // set initial pro amoount montly
      setProductId(resData[1]?.stripeProduct?.monthlyPriceId);

      setSubscriptionId(resData[1]?.stripeProduct?.stripeProductId);

      if (resData.length > 1) {
        // To set the stripe monthly and yearly ids for pro and premium
        setStripeProPlanProductData(resData[1]?.stripeProduct);
        setStripePremiumPlanProductData(resData[2]?.stripeProduct);

        // Get all dissplay keys
        const allKeys = Object.keys(resData[1]).filter((key) => {
          const value = resData[1][key];
          return (
            key !== "__typename" &&
            typeof value !== "object" &&
            key !== "productUUID" &&
            key !== "plan"
          );
        });

        const struturedFeatureItem: (
          | string
          | number
          | boolean
          | ProductStripeI
          | ProductAmountI
          | undefined
        )[][] = Array.from({ length: allKeys.length }, () => []);

        allKeys.forEach((allKeysItem: string, allKeysIndex: number) => {
          // for each display key gett corresponf and save on the strutured
          //   [allKeyIndex][itemm INdex]

          resData.forEach((itemData: ProductsI, itemIndex: number) => {
            struturedFeatureItem[allKeysIndex][0] =
              separateCamelCase(allKeysItem);
            struturedFeatureItem[allKeysIndex][itemIndex + 1] =
              itemData[allKeysItem as keyof ProductsI];
          });
        });

        setSubscriptionFeatures([...struturedFeatureItem]);
      }
    }

    if (error) {
      console.log("Error -- ", error);
      toast.error(error?.message || "Unexpected error occured");
    }
  }, [data, error]);

  // When iis sub in view changes
  useEffect(() => {
    setOpenSubFeatures(
      isSubScribeInView === undefined ? true : isSubScribeInView
    );
  }, [isSubScribeInView]);

  if (loading) return <SpinnerLoader loading={true} />;

  return (
    <div className="subscription-main">
      {openSubFeatures ? (
        <>
          {/* Header */}
          <div className="subscription-main__header">
            <span
              className={`${
                isMonthlySubSelected ? "active-subscription-header" : ""
              }`}
              onClick={() => {
                setIsMonthlySubSelected(true);
                handlesetProductId(selectedPlan, true);
              }}
            >
              Monthly
            </span>
            <span
              className={`${
                !isMonthlySubSelected ? "active-subscription-header" : ""
              }`}
              onClick={() => {
                setIsMonthlySubSelected(false);

                handlesetProductId(selectedPlan, false);
              }}
            >
              Yearly
            </span>
          </div>

          {/* Table */}
          <div className="table-scroller">
            <div className="subscription-table">
              {/* Table head */}
              <div className="subscription-table__th table-row">
                <p className="subscription-table__th-item table-row__item">
                  Feature
                </p>
                <div className="subscription-table__th-item table-row__item starter-bg-color ">
                  <p>Starter</p>
                </div>

                <div
                  className={`${
                    selectedPlan === "pro" ? "active-subscription-header " : ""
                  }
                pro-bg-color
                  subscription-table__th-item table-row__item cursored`}
                  onClick={() => setSelectedPlan("pro")}
                >
                  <p>Pro</p>

                  <p
                    className={`${
                      selectedPlan === "pro"
                        ? "subscription-table__th-item-selected"
                        : "hide-plan-selection"
                    }
                  `}
                  >
                    Selected
                  </p>
                </div>

                <div
                  className={`

             ${selectedPlan === "premium" ? "active-subscription-header " : ""}
                premium-bd-color
                subscription-table__th-item table-row__item cursored`}
                  onClick={() => setSelectedPlan("premium")}
                >
                  <p>Premium</p>

                  <p
                    className={`${
                      selectedPlan === "premium"
                        ? "subscription-table__th-item-selected"
                        : "hide-plan-selection"
                    } `}
                  >
                    Selected
                  </p>
                </div>
              </div>

              {/* Sub Price  */}
              <div className="subscription-table__th table-row">
                <p className="subscription-table__th-item table-row__item"></p>

                {subscriptionPrice?.length > 0 &&
                  [...subscriptionPrice[isMonthlySubSelected ? 0 : 1]].map(
                    (item, index) => (
                      <div
                        key={index}
                        className={`${
                          selectedPlan === "pro" && index === 1
                            ? "active-subscription-header "
                            : ""
                        }

                  ${
                    selectedPlan === "premium" && index === 2
                      ? "active-subscription-header "
                      : ""
                  }
                    subscription-table__th-item table-row__item table-row__item-price cursored`}
                        onClick={() =>
                          setSelectedPlan(
                            selectedPlan === "pro" ? "premium" : "pro"
                          )
                        }
                      >
                        <p>
                          {item !== "Free" ? "$" : ""} {item}
                        </p>
                      </div>
                    )
                  )}
              </div>

              {/* Table body */}

              <div>
                {subscriptionFeatures?.length > 0 &&
                  subscriptionFeatures?.map((subItems, subItemIndex) => (
                    <div className="table-row" key={subItemIndex}>
                      {subItems?.map((subItem, subIndex) => (
                        <div
                          key={subIndex}
                          className={`
                        ${
                          selectedPlan === "pro" && subIndex === 2
                            ? "active-subscription-header "
                            : ""
                        }

                         ${
                           selectedPlan === "premium" && subIndex === 3
                             ? "active-subscription-header "
                             : ""
                         }

                        ${subIndex !== 0 && subIndex !== 1 ? "cursored" : ""}


                        table-row__item
                        `}
                          onClick={() => {
                            setSelectedPlan((prev) => {
                              let plan =
                                selectedPlan === "pro" && subIndex === 3
                                  ? "premium"
                                  : selectedPlan === "premium" && subIndex === 2
                                  ? "pro"
                                  : selectedPlan;

                              handlesetProductId(plan, isMonthlySubSelected);

                              return plan;
                            });
                          }}
                        >
                          {subItem?.toString() !== "true" ? (
                            <p>{subItem?.toString()}</p>
                          ) : (
                            <div>
                              {CheckSvg(
                                (selectedPlan === "pro" && subIndex === 2) ||
                                  (selectedPlan === "premium" && subIndex === 3)
                                  ? "#ffffff"
                                  : ""
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="subscription--stripe-container">
          <StripePayment
            productId={productId}
            subscriptionId={subscriptionId}
            isTrail={isTrail || false}
            isSubscriptionPayment={true}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionPayment;

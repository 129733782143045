import { useEffect, useState } from "react";
import "./Medsynopsis.css";
import { useDispatch } from "react-redux";
import { quizAction } from "../../state/medSynopsis/quizSlice";
import { useNavigate } from "react-router-dom";
import { MedSynopsisCategoryI } from "../../types/Medsynopsis";

const TimedQuiz = ({ quizList }: { quizList: MedSynopsisCategoryI[] }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1024);
  const [quizes, setQuizes] = useState(quizList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="grid-container">
      {quizes?.map((item, index) => {
        const itemsPerRow = isLargeScreen ? 3 : 2;
        const row = Math.floor(index / itemsPerRow) + 1;
        const col = (index % itemsPerRow) + 1;

        const isTargetItem = isLargeScreen
          ? col === 2 // Middle item in large screens
          : col === 2; // Second item in small screens

        const heightClass =
          isTargetItem && row % 2 === 1
            ? "shorter"
            : isTargetItem && row % 2 === 0
            ? "taller"
            : "";

        return (
          <div
            key={index}
            role="button"
            onClick={() => {
              dispatch(
                quizAction.setQuiz({
                  image: item.coverImage,
                  type: "timed",
                  title: item.title,
                  isRandom: false,
                })
              );
              navigate(`/dashboard/med-synopsis/${item.categoryUUID}`);
            }}
            className={`grid-item ${heightClass}`}
          >
            <img
              src={item.coverImage || "/images/medSynopsis/medrobot.png"}
              alt={item.title}
              className="quiz-img"
            />
            <p style={{ textAlign: "center" }}>{item.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default TimedQuiz;

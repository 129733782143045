import React, { useEffect, useRef, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import "./HomePage.css";
import Pitch from "../../components/Pitch/Pitch";
import Footer from "../../components/Footer/Footer";
import LazyLoad from "react-lazyload";
import SampleQuiz from "../../components/SampleQuiz/SampleQuiz";
import SEO from "../../components/SEO/SEO";
import GradientPosition from "../../hooks/MousePosition";
import {
  StarTracBigSvg,
  StarTrackSmallSvg,
} from "../../components/Svgs/svgsComponents";
import useCustomInVew from "../../hooks/useCustomInVew";
import { useNavigate, useSearchParams } from "react-router-dom";

const HomePage: React.FC = () => {
  const [showQuizPreview, setShowQuizPreview] = useState<boolean>(false);

  const [showQuizDesktopPreview, setShowQuizDesktopPreview] = useState(false);

  const { x, y } = GradientPosition();

  const [gradientPosition, setGradientPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // ref Preview
  const refPreview = useRef<HTMLDivElement | null>(null);

  const previewInview = useCustomInVew(refPreview, 0.43);

  useEffect(() => {
    // Smoothly update the gradient position

    setTimeout(() => {
      setGradientPosition({ x, y });
    }, 400);

    if (searchParams.get("redirectTo") === "medsynopsis") {
      navigate("/dashboard/med-synopsis");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, previewInview]);

  return (
    <section
      className="home"
      style={{
        position: "relative",
        background: "#000036",
      }}
    >
      {/* This pseudo-element will hold the gradient background and transition its opacity */}
      <div
        className={`${
          x === gradientPosition.x
            ? "hide-mobile-mouse-track-pink-bg"
            : "hide-mobile-mouse-track-blue-bg"
        }  track-mouse`}
        style={{
          content: "",
          position: "absolute",
          top: `${gradientPosition.y - 50}px`,
          left: `${gradientPosition.x + 50}px `,

          // backgroundImage: `radial-gradient(circle farthest-corner at ${gradientPosition.x}px ${gradientPosition.y}px, rgba(227, 97, 225, 0.2) 1%, #000036 5%),
          // linear-gradient(100.98deg, rgba(227, 97, 225, 0.3) 14.41%, rgba(102, 145, 233, 0.6) 85.86%)`,
          opacity: x === gradientPosition.x ? 0.8 : 0.75,
          transition:
            "opacity .95s ease-in-out, background-image .95s ease-in-out",
          zIndex: 0, // Keep this in the background
          pointerEvents: "none", // Ensure it doesn't interfere with content
        }}
      ></div>

      <span
        className="hide-stars"
        style={{
          content: "",
          position: "absolute",
          top: `${gradientPosition.y - 80}px`,
          left: `${gradientPosition.x + 20}px `,
          opacity: x === gradientPosition.x ? 0.8 : 0.35,
          transition: "opacity .5s ease-in-out",
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        {StarTracBigSvg()}
      </span>

      <span
        className="hide-stars"
        style={{
          content: "",
          position: "absolute",
          top: `${gradientPosition.y + 20}px`,
          left: `${gradientPosition.x + 100}px `,
          opacity: x === gradientPosition.x ? 0.7 : 0.25,
          transition: "opacity .7s ease-in-out",
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        {StarTrackSmallSvg()}
      </span>

      {/* Content Layer */}
      <div style={{ position: "relative", zIndex: 1 }}>
        {/* for search engine optimization */}
        <SEO
          title="Home"
          description="Dive Into Medical Trivia & Craft Your Quizzes with MedScroll"
          name={"medscroll"}
          type="article"
        />

        <img
          className="decor-gradient-top-right"
          src="/images/eclipse-top-right.png"
          alt="eclipse"
        />

        <NavBar />

        <Hero />

        <About />

        {/* Preview Quiz */}
        <div ref={refPreview} className="home__preview ">
          <div className="container home__preview-content">
            <div
              className={`
              ${previewInview ? "animate-slide-in-left" : ""}

              home__preview-content__left`}
            >
              <h3 className="gradient-text-rainbow">Preview Quiz</h3>

              <p>
                 With MedScroll, you're not just learning; you're actively
                participating in a community that values knowledge, fun, and
                continuous improvement.
              </p>
            </div>

            {/* Desktop */}

            <div
              className={`
                home__preview-img

                ${previewInview ? "animate-slide-in-right" : ""}

                home__preview-img__content-hide`}
              onClick={() => {
                setShowQuizDesktopPreview(true);
              }}
            >
              <LazyLoad offset={100}>
                <img
                  src="/images/phone-play-desktop.png"
                  alt="play quix icon"
                />
              </LazyLoad>
              <div className="home__preview-img__content ">
                {!showQuizDesktopPreview ? (
                  <p className="home__preview-img__content-text">
                    Test Your knowledge
                  </p>
                ) : (
                  <SampleQuiz setRestartQuiz={setShowQuizDesktopPreview} />
                )}
              </div>
            </div>

            {/* Mobile */}

            {!showQuizPreview ? (
              <div
                className={`
                    home__preview-img
                    ${previewInview ? "animate-slide-in-right" : ""}
                    home__preview-img__content-show`}
                onClick={() => {
                  setShowQuizPreview(true);
                }}
              >
                <LazyLoad offset={100}>
                  <img
                    src="/images/phone-play-desktop.png"
                    alt="play quix icon"
                  />
                </LazyLoad>

                {!showQuizPreview && (
                  <div className="home__preview-img__content-mobile ">
                    <p className="home__preview-img__content-text">
                      Test Your knowledge
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <SampleQuiz setRestartQuiz={setShowQuizPreview} />
            )}
          </div>
        </div>

        <Pitch />

        <Footer />
      </div>
    </section>
  );
};

export default HomePage;

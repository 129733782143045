import { useState } from "react";
import "./MedSynopsisFeedback.css";
import AISummary from "./AISummary";
import AIFeedback from "./AIFeedback";
import MarkingScheme from "./MarkingScheme";
import { LuCopy, LuVolume2, LuVolumeX } from "react-icons/lu";
import { BiDislike } from "react-icons/bi";
import Modal from "../../Modal/Modal";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../../state";
import { UserI } from "../../../types/AuthResponse";
import { toast } from "react-toastify";
import { IoCopy } from "react-icons/io5";
import FeedbackImage from "./FeedbackImage";

const MedSynopsisFeedback = () => {
  const [activeFeedback, setActiveFeedback] = useState("AI Summary");
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const feedback = useSelector(
    (state: RootState) => state.medSynopsisFeedback.feedback
  );
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [copied, setCopied] = useState(false);
  const userData = useSelector((state: RootState) => state.user) as UserI;

  const handleSpeak = () => {
    if (
      activeFeedback === "AI feedback" &&
      (!userData.subscription?.tokenBalance ||
        userData.subscription?.tokenBalance < 0)
    ) {
      toast.error("You need to subscribe to access ai feedback");
      return;
    }

    // Check if the browser supports the Web Speech API
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(
        activeFeedback === "AI feedback"
          ? feedback?.aIFeedback
          : feedback?.caseAISummary
      );

      if (isSpeaking) {
        window.speechSynthesis.cancel();
        setIsSpeaking(false);
        return;
      }

      // Optionally, you can configure voice options
      utterance.lang = "en-US"; // Set language
      utterance.pitch = 1; // Default pitch
      utterance.rate = 1; // Default rate

      utterance.onstart = () => {
        setIsSpeaking(true);
      };
      utterance.onend = () => {
        setIsSpeaking(false);
      };
      // Speak the text
      window.speechSynthesis.speak(utterance);
    } else {
      alert("Sorry, your browser does not support text-to-speech!");
    }
  };

  const handleCopy = () => {
    if (
      activeFeedback === "AI feedback" &&
      (!userData.subscription?.tokenBalance ||
        userData.subscription?.tokenBalance < 0)
    ) {
      toast.error("You need to subscribe to access ai feedback");
      return;
    }

    if (!feedback) {
      toast.error("No text available");
      return;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      activeFeedback === "AI feedback"
        ? feedback.aIFeedback
        : feedback.caseAISummary,
      "text/html"
    );
    navigator.clipboard.writeText(`${doc.body.textContent}`);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <div className="feedback-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="profile-image-container">
          <div>
            <FeedbackImage
              imageUrl={userData.profileImage || "/images/guest.svg"}
            />
          </div>
        </div>

        <div className="score-container">
          <div
            style={{
              marginTop: "auto",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Your score</p>
            <span>{feedback?.score}</span>
          </div>
        </div>
        <div className="points-container">
          <p>
            {feedback?.score}/100 <span>points</span>
          </p>
        </div>
      </div>

      <div className="record-container">
        <div>
          <p>Accuracy:</p>
          <span>{feedback?.scoreSummary.accuracy}</span>
        </div>
        <div>
          <p>Clarity & Conciseness:</p>
          <span>{feedback?.scoreSummary.clarityConciseness}</span>
        </div>
        <div>
          <p>Relevance:</p>
          <span>{feedback?.scoreSummary.relevance}</span>
        </div>
        <div>
          <p>Organization & Structure:</p>
          <span>{feedback?.scoreSummary.organizationStructure}</span>
        </div>
        <div>
          <p>Time to Completion:</p>
          <span>
            {feedback?.scoreSummary.completionTime} <small>(10 min)</small>
          </span>
        </div>
      </div>

      {/* feedback */}
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
        >
          <div
            role="button"
            onClick={() => setActiveFeedback("AI Summary")}
            className={`feedback-item ${
              activeFeedback === "AI Summary" ? "active-feedback" : ""
            }`}
            style={{ borderTopLeftRadius: 20 }}
          >
            <div>AI Summary</div>
          </div>
          <div
            role="button"
            onClick={() => setActiveFeedback("AI feedback")}
            className={`feedback-item ${
              activeFeedback === "AI feedback" ? "active-feedback" : ""
            }`}
          >
            <div>AI feedback</div>
          </div>
          <div
            role="button"
            onClick={() => setActiveFeedback("Marking scheme")}
            className={`feedback-item ${
              activeFeedback === "Marking scheme" ? "active-feedback" : ""
            }`}
            style={{ borderTopRightRadius: 20 }}
          >
            <div>Marking scheme</div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            border: "1px solid #B8B8B8",
            padding: 20,
            margin: "auto",
            height: 350,
            borderRadius: 20,
            borderTopLeftRadius: 0,
            overflowY: "auto",
          }}
        >
          {activeFeedback === "AI Summary" ? (
            <AISummary summary={feedback?.caseAISummary} />
          ) : activeFeedback === "AI feedback" ? (
            <AIFeedback summary={feedback?.aIFeedback} />
          ) : (
            <MarkingScheme />
          )}
        </div>
        <div
          style={{
            alignSelf: "start",
            marginTop: 10,
            display: "flex",
            gap: 15,
            alignItems: "center",
          }}
        >
          {activeFeedback !== "Marking scheme" && (
            <>
              {copied ? (
                <IoCopy size={20} role="button" style={{ cursor: "pointer" }} />
              ) : (
                <LuCopy
                  size={20}
                  role="button"
                  onClick={handleCopy}
                  style={{ cursor: "pointer" }}
                />
              )}
            </>
          )}
          {!isSpeaking ? (
            <LuVolume2
              size={20}
              role="button"
              onClick={handleSpeak}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <LuVolumeX
              size={20}
              role="button"
              onClick={() => handleSpeak()}
              style={{ cursor: "pointer" }}
            />
          )}
          <BiDislike size={20} role="button" style={{ cursor: "pointer" }} />
        </div>

        <div
          role="button"
          onClick={() => setShowSummaryModal(true)}
          className={`filter-item-active gradiat-border filter-item`}
        >
          My Summary
        </div>
        {showSummaryModal && (
          <Modal extraFunc={() => setShowSummaryModal(false)}>
            <div className="summary-modal">
              <div
                role="button"
                onClick={() => setShowSummaryModal(false)}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 20,
                  cursor: "pointer",
                }}
              >
                <IoMdClose size={20} />
              </div>
              <h5 style={{ textAlign: "center" }}>My Summary</h5>
              <div className="summary-container">
                <div
                  dangerouslySetInnerHTML={{
                    __html: feedback?.userSummary || "",
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default MedSynopsisFeedback;

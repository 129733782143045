import React, { Dispatch, useEffect, useRef } from "react";
import "./Timer.css";
const Timer = ({
  timeCount,
  setTimeCount,
  pauseTime,
}: {
  timeCount: number;
  setTimeCount: Dispatch<React.SetStateAction<number>>;
  pauseTime: boolean;
}) => {
  // Varibles
  const maxTime = 30;
  const minTime = 0;

  const sliderRef = useRef<HTMLInputElement>(null);

  // Function to handle changes in the range input
  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSeconds = parseInt(event.target.value); // Convert the input value to an integer
    setTimeCount(newSeconds); // Update the seconds state
  };

  // Use effect for Time Change andCount
  useEffect(() => {
    // Handle Time count down
    if (!pauseTime) {
      const timerInterval = setInterval(() => {
        if (timeCount > 0) {
          setTimeCount((prevState) => prevState - 1);
        } else {
          setTimeCount(30);
        }
      }, 1000);

      // Handle slider movement
      if (sliderRef.current) {
        sliderRef.current.style.background = `linear-gradient(to right, #30a8ec 0%, #30a8ec ${
          ((timeCount - minTime) / (maxTime - minTime)) * 100
        }%, #b0dcf5 ${
          ((timeCount - minTime) / (maxTime - minTime)) * 100
        }%, #b0dcf5 100%)`;

        sliderRef.current.oninput = function () {
          sliderRef.current!.style.background = `linear-gradient(to right, #30a8ec 0%, #30a8ec ${
            ((timeCount - minTime) / (maxTime - minTime)) * 100
          }%, #b0dcf5 ${
            ((timeCount - minTime) / (maxTime - minTime)) * 100
          }%, #b0dcf5 100%)`;
        };
      }

      // Clean up the interval when the component unmounts
      return () => clearInterval(timerInterval);
    }
  }, [pauseTime, setTimeCount, timeCount]);

  return (
    <div className="timer">
      <div className="timer__details">
        <p className="timer__count">{timeCount}</p>
        <p className="timer__delimeter">sec</p>
      </div>
      <div className="timer__range">
        <input
          type="range"
          max={maxTime}
          min={minTime}
          value={timeCount}
          step={1}
          onChange={handleRangeChange}
          ref={sliderRef}
          style={{}}
        />
      </div>
    </div>
  );
};

export default Timer;
